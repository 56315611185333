import React from "react";
import { Box, Button } from "@mui/material";
import heroBanner from "../assets/Subtract.png";

const HeroSection = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        padding: "10px",
        marginTop: "30px",
        alignItems: "center",
        textAlign: { xs: "center", md: "left" },
      }}
    >
      {/* Image Section */}
      <Box
        sx={{
          animation: "fadeIn 1s ease-out, slideInFromLeft 1s ease-out",
          "@keyframes fadeIn": {
            "0%": { opacity: 0 },
            "100%": { opacity: 1 },
          },
          "@keyframes slideInFromLeft": {
            "0%": { transform: "translateX(-100px)" },
            "100%": { transform: "translateX(0)" },
          },
          width: { xs: "100%", md: "50%" },
        }}
      >
        <img
          style={{
            width: "100%",
            maxWidth: "600px",
            height: "auto",
          }}
          src={heroBanner}
          alt="hero-banner"
        />
      </Box>

      {/* Text Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", md: "flex-start" },
          animation: "fadeIn 1.5s ease-out, slideInFromRight 1.5s ease-out",
          "@keyframes slideInFromRight": {
            "0%": { transform: "translateX(100px)" },
            "100%": { transform: "translateX(0)" },
          },
          width: { xs: "100%", md: "50%" },
        }}
      >
        {/* Info Box */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "300px", md: "412px" },
            height: "98px",
            backgroundColor: "#F6F6F6",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            marginBottom: "20px",
          }}
        >
          <h1 style={{ fontSize: "40px", color: "red" }}>150 +</h1>
          <h3 style={{ color: "red", marginLeft: "10px" }}>
            Innovative Excellence
          </h3>
        </Box>

        {/* Overlapping Box */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "300px", md: "412px" },
            height: "98px",
            backgroundColor: "black",
            borderRadius: "12px",
            marginBottom: "20px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          <h1 style={{ fontSize: "40px", color: "white" }}>15</h1>
          <h3 style={{ color: "white", marginLeft: "10px" }}>
            Client-Centric Approach
          </h3>
        </Box>

        {/* Heading and CTA */}
        <Box>
          <h1
            style={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: "bolder",
              fontSize: "50px",
            }}
          >
            Innovation
          </h1>
          <h1
            style={{
              fontFamily: "'Poppins', sans-serif",
              fontSize: "50px",
              fontWeight: "100",
            }}
          >
            IT Solutions for
          </h1>
          <h3
            style={{
              fontFamily: "'Poppins', sans-serif",
              fontSize: "18px",
              letterSpacing: ".3rem",
            }}
          >
            YOUR BUSINESS
          </h3>
          <Button
            sx={{
              marginTop: "20px",
              borderRadius: "30px",
              backgroundColor: "#FF1A1A",
              color: "white",
              height: "50px",
              width: { xs: "120px", md: "155px" },
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                border: "1px solid black",
              },
            }}
          >
            Get Started
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default HeroSection;
