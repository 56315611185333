import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import { Box, Typography } from "@mui/material";
import dedert from "../assets/portfolio/dedert.png";
import fastexchange from "../assets/portfolio/fastexchange.png"
import phonehub from "../assets/portfolio/phonehub.png"
import smartcomputer from "../assets/portfolio/smartcomputer.png"
import state from "../assets/portfolio/state.png"
import tropclair from "../assets/portfolio/tropclair.png"
import uranus from "../assets/portfolio/uranuseng.png"
import gopayra from "../assets/portfolio/gopayra.png"

const ProjectSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, easing: "ease-in-out", once: true });
  }, []);
  var portfolio = [{
    name:'Dedert',
    link:'https://dedert.com/',
    image:dedert
  },{
    name:'Fast Exchange',
    link:'https://fastexchange.shop/',
    image:fastexchange
  },{
    name:'Discount PhoneHub',
    link:'https://www.discountphonehub.com/',
    image:phonehub
  },{
    name:'Smart Computer View',
    link:'https://smartviewcomputer.com/',
    image:smartcomputer
  },{
    name:'State Apparels',
    link:'https://stateapparels.com/',
    image:state
  },{
    name:'Trop Clair',
    link:'https://tropclair.net/',
    image:tropclair
  },{
    name:'Uranus Engineering',
    link:'https://uranuseng.com/',
    image:uranus
  },{
    name:'GoPayra',
    link:'https://gopayra.com/',
    image:gopayra
  },];

  return (
    <Box
      sx={{
        marginTop: "50px",
        backgroundColor: "rgba(255, 26, 26, 0.07)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: { xs: "20px", sm: "40px 20px" }, // Adjust padding based on screen size
      }}
    >
      {/* Section Title */}
      <Box sx={{ textAlign: "center", mb: 6 }}>
        <Typography
          variant="h3"
          component="h2"
          gutterBottom
          sx={{
            fontWeight: "bold",
            fontFamily: "'Poppins', sans-serif",
            color: "text.primary",
          }}
          data-aos="fade-up"
        >
          Our Recent Works
        </Typography>
      </Box>

      {/* Image Gallery */}
      <Box
        sx={{
          width: { xs: "100%", sm: "70%" }, // Full width on mobile, 70% on larger screens
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          flexWrap: "wrap",
        }}
      >
        {portfolio.map((img, index) => (
          <div>
            <a href={img.link}>
            <img
            key={index}
            style={{
              height: "auto",
              width: "100%", 
              aspectRatio: "16/9",
              maxWidth: "386px", 
              borderRadius: "12px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              objectFit: "cover",
            }}
            src={img.image}
            alt={`Project ${index + 1}`}
            data-aos="zoom-in"
            data-aos-delay={`${index * 100}`}
          />
            </a>
          <a href={img.link} style={{
              textDecoration:'none'
            }}>
            <h4 style={{
              textAlign:'center'
            }}>
              {img.name}
            </h4>
          </a>
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default ProjectSection;
