import React, { useState } from "react";
import { Box, Button, Typography, IconButton, Drawer, List, ListItem, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import CompanyIcon from "../assets/logo.png";

const Header = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        padding: { xs: "10px 20px", md: "10px 40px" },
        marginTop: "20px",
        backgroundColor: "#fff",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        position: "sticky",
        top: 0,
        zIndex: 1000,
      }}
    >
      {/* Logo Section */}
      <img
        style={{
          width: "150px",
          height: "auto",
          cursor: "pointer",
          marginLeft: { xs: "10px", md: "90px" },
        }}
        src={CompanyIcon}
        alt="Company Logo"
      />

      {/* Navigation Links (Desktop) */}
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          alignItems: "center",
          gap: "20px",
        }}
      >
        {["Home", "About Us", "Services", "Blogs"].map((item, index) => (
          <Typography
            key={index}
            variant="h6"
            component="div"
            sx={{
              cursor: "pointer",
              fontFamily: "'Poppins', sans-serif",
              fontWeight: "bold",
              color: "text.primary",
              transition: "color 0.3s ease-in-out",
              "&:hover": { color: "red" },
              "&:after": {
                content: '""',
                position: "absolute",
                bottom: "-5px",
                left: 0,
                width: 0,
                height: "2px",
                backgroundColor: "red",
                transition: "width 0.3s ease-in-out",
              },
              "&:hover:after": { width: "100%" },
            }}
          >
            {item}
          </Typography>
        ))}
      </Box>

      {/* Contact Button (Desktop) */}
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Button
          sx={{
            borderRadius: "30px",
            backgroundColor: "black",
            color: "white",
            height: "50px",
            width: "151px",
            fontSize: "14px",
            textTransform: "none",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              backgroundColor: "white",
              color: "#FF1A1A",
              border: "1px solid #FF1A1A",
            },
          }}
        >
          Contact Us
        </Button>
      </Box>

      {/* Hamburger Menu (Mobile) */}
      <IconButton onClick={toggleDrawer} sx={{ display: { xs: "block", md: "none" } }}>
        <MenuIcon sx={{ fontSize: 32, color: "black" }} />
      </IconButton>

      {/* Drawer for Mobile Navigation */}
      <Drawer anchor="right" open={open} onClose={toggleDrawer}>
        <Box sx={{ width: "250px", textAlign: "center", padding: "20px" }}>
          <IconButton onClick={toggleDrawer} sx={{ position: "absolute", top: "10px", right: "10px" }}>
            <CloseIcon sx={{ color: "#FF1A1A" }} />
          </IconButton>
          <List>
            {["Home", "About Us", "Services", "Blogs", "Contact Us"].map((text, index) => (
              <ListItem button key={index} onClick={toggleDrawer}>
                <ListItemText
                  primary={text}
                  sx={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "black",
                    "&:hover": { color: "#FF1A1A" },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Header;
